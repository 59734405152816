// ==========================================================================
// Header
// ==========================================================================

.header {
  background:transparent url('../img/pdb-cover.jpg') no-repeat center center;
  background-size: cover;
  min-height: 400px;
  position: relative;
  text-align: center;
}

.header__rabbit {
  text-align: center;

  .icon {
    height: 9.1rem;
    width: 11.1rem;
  }
}

.header__baseline {
  left: 50%;
  margin: 0 auto;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -55%);
  width: 75%;

  .icon {
    animation: bounceInDown 1s 1 ease, pulse 1s infinite ease;
    animation-delay: 1s, 2s;
    animation-fill-mode: both, none;
    height: 42.6rem;
    max-width: 100%;
    width: 91.3rem;
  }
}

@media (min-width: $screen-sm) {
  .header {
    min-height: 600px;
  }
}

@media (min-width: $screen-md) {
  .header {
    min-height: 90vh;
  }
}