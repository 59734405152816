.coworker {
  margin: 4rem 0 0;
}


  .coworker__header {
    border-radius: 50%;
    display: table;
    margin: 0 auto 2rem;
    position: relative;
    transition: transform .5s;

    &:hover {
      transform: scale(1.2);
    }
  }

    .coworker__image {
      border-radius: 50%;
      display: block;
      margin: 0 auto;
      position: relative;
      max-width: 100%;
      z-index: 1;
    }

  .coworker__content {

  }

    .coworker__name {
      color: $mustard;
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 2.2rem;
      margin: 0 0 .5rem;
    }

    .coworker__function {
      font-size: 1.4rem;
      line-height: 2.2rem;
      margin: 0;
    }

    .coworker__website {
      color: #fff;
      font-size: 1.4rem;
      line-height: 2.2rem;
      transition: color .5s;

      &:hover {
        color: $mustard;
      }
    }

.coworker.has-image {
  .coworker__header {
    &::before,
    &::after {
      border-radius: 50%;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &::before {
      background: $mustard;
      opacity: .2;
      z-index: 2;
    }

    &::after {
      background: #16195e;
      opacity: .4;
      z-index: 3;
    }

    @supports ((mix-blend-mode: multiply) and (mix-blend-mode: lighten)) {
      &::before {
        mix-blend-mode: multiply;
        opacity: 1;
        z-index: 2;
      }

      &::after {
        mix-blend-mode: lighten;
        opacity: 1;
        z-index: 3;
      }
    }
  }

  .coworker__image {
    filter: grayscale(1);
  }
}
