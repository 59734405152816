// ==========================================================================
// Typography
// ==========================================================================

a {
  color: $buttercup;
  transition: color 0.25s ease-in-out;

  &:hover,
  &:active {
    color: inherit;
  }
}

strong,
b {
  font-weight: bold;
}

em,
i {

}

sub, sup {
  font-size: 60%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

p,
ul,
ol,
dl,
blockquote,
.blockquote,
table,
.table,
img,
figure,
.media {

}

small {

}

hr,
.hr {

}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

p {

}

blockquote {

}
