// ==========================================================================
// Fonts
// ==========================================================================

@font-face {
  font-family: 'Qanelas';
  src:  url('../fonts/qanelas/qanelas.woff2') format('woff2'),
        url('../fonts/qanelas/qanelas.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'Qanelas';
  src:  url('../fonts/qanelas/qanelas-bold.woff2') format('woff2'),
        url('../fonts/qanelas/qanelas-bold.woff') format('woff');
  font-weight: bold;
}