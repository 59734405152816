// ==========================================================================
// Utils
// ==========================================================================

.text--center {
  text-align: center;
}

.text--right {
  text-align: right;
}

.block-table {
  display: table;
  width: 100%;
}

.block-table__cell {
  display: table-cell;
  vertical-align: middle;
}

.sr-only {
  display: none;
}

.color--buttercup {
  color: $buttercup;
}

.color--pink-salmon {
  color: $pink-salmon;
}

.color--picton-blue {
  color: $picton-blue;
}

@media (min-width: $screen-sm) {

}

.clear {
  clear: both;
}
