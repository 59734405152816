// ==========================================================================
// Main
// ==========================================================================

.main {

}

.section {
  padding: 10rem 0;
  text-align: center;
}

.section__title {
  display: inline-block;
  font-size: 4.7rem;
  font-weight: bold;
  line-height: 4.7rem;
  margin: 0 0 5rem;
  padding: 0 2rem;
  position: relative;
  text-align: center;
  z-index: 1;

  &:after {
    background-color: $mustard;
    bottom: 0.44rem;
    content: '';
    height: 0.88rem;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
}

.section--join {
  p {
    backface-visibility: hidden;
    font-size: 2.5rem;
    line-height: 1.3;
    margin: 2.5rem 0 0;

    &:first-child {
      margin-top: 0;
    }

    span {
      animation-fill-mode: both;
      opacity: 0;
    }
  }
}

#map-canvas {
  height: 400px;
}

.section--services {
  background-color: $geraldine;

  .section__title {
    margin-bottom: 8.5rem;

    span {
      animation-fill-mode: both;
      display: inline-block;
      visibility: hidden;
    }
  }

  .dot {
    text-align: left;

    &.dot--coffee {
      .dot__background {
        margin-left: 5% * 3.8;
        margin-top: 5% * 3.8;
      }

      .dot__icon {
        height: 10.1% * 6.9;
        left: 1% * 6.9;
        top: 3.2% * 6.9;
        width: 8.7% * 6.9;
      }

      .dot__title {
        margin-left: 4% * 3.8;
      }
    }

    &.dot--nap {
      .dot__background {
        margin-left: 10.7% * 3.8;
        margin-top: 7.15% * 3.8;
      }

      .dot__icon {
        height: 10.3% * 6.9;
        left: -4.7% * 6.9;
        top: 2.6% * 6.9;
        width: 17.2% * 6.9;
      }

      .dot__title {
        margin-left: 6% * 3.8;
      }
    }

    &.dot--space {
      .dot__background {
        margin-left: 8% * 3.8;
        margin-top: 11.5% * 3.8;
      }

      .dot__icon {
        height: 12.4% * 6.9;
        left: -3.2% * 6.9;
        top: 3.4% * 6.9;
        width: 15.7% * 6.9;
      }

      .dot__title {
        margin-left: 2% * 3.8;
      }
    }

    &.dot--kitchen {
      .dot__background {
        margin-left: 11% * 3.8;
        margin-top: 10% * 3.8;
      }

      .dot__icon {
        height: 7.9% * 6.9;
        left: -1.7% * 6.9;
        top: 5% * 6.9;
        width: 11.4% * 6.9;
      }

      .dot__title {
        margin-left: 4% * 3.8;
      }
    }

    &.dot--connexion {
      .dot__background {
        margin-left: 10% * 3.8;
        margin-top: 0;
      }

      .dot__icon {
        height: 7.2% * 6.9;
        left: -1.3% * 6.9;
        top: 5.8% * 6.9;
        width: 9.2% * 6.9;
      }

      .dot__title {
        margin-left: 6% * 3.8;
      }
    }

    &.dot--atmosphere {
      .dot__background {
        margin-left: 9% * 3.8;
        margin-top: 6.5% * 3.8;
      }

      .dot__icon {
        height: 6.5% * 6.9;
        left: -1.8% * 6.9;
        top: 5% * 6.9;
        width: 10.1% * 6.9;
      }

      .dot__title {
        margin-left: 5% * 3.8;
      }
    }

    &.dot--whiteboard {
      .dot__background {
        margin-left: 10.4% * 3.8;
        margin-top: 11.5% * 3.8;
      }

      .dot__icon {
        height: 12.4% * 6.9;
        left: 0.4% * 6.9;
        top: 1.8% * 6.9;
        width: 8.4% * 6.9;
      }

      .dot__title {
        margin-left: 4.5% * 3.8;
      }
    }

    &.dot--place {
      .dot__background {
        margin-left: 1.5% * 3.8;
        margin-top: 9.5% * 3.8;
      }

      .dot__icon {
        height:  7.9% * 6.9;
        left: 3% * 6.9;
        top: 4.4% * 6.9;
        width: 5.3% * 6.9;
      }

      .dot__title {
        margin-left: 1% * 3.8;
      }
    }
  }

  .dot__background {
    background: $mustard;
    border-radius: 50%;
    height: 0;
    margin-bottom: 2.75% * 3.8;
    padding-top: 14.5% * 3.8;
    position: relative;
    visibility: hidden;
    width: 14.5% * 3.8;
    z-index: 10;
  }

  .dot__icon {
    position: absolute;
  }

  .dot__title {
    display: inline-block;
    font-size: 4.4vw;
    line-height: 5.13vw;
    margin: 0;
    text-align: center;
    visibility: hidden;
  }
}

.section--photos {
  padding: 0;

  .section__photo {
    background-position: center;
    background-size: cover;
    min-height: 100vw/(16/9);
  }
}

.section--coworkers {
  background-color: $wisteria;
  color: $white;
}

.section--offers {
  p {
    font-size: 2rem;
    line-height: 2.4rem;
    margin: 0 0 2.4rem;
  }
}

.section--about {
  background-color: $mustard;
  color: $mondo;

  .section__title {
    margin-bottom: 8.5rem;

    &:after {
      background-color: #fff;
    }
  }

  .about__icon {
    display: block;
    margin: 0 auto 5rem;
  }

  .about__introduction {
    text-align: left;

    p {
      font-size: 2.8rem;
      line-height: 3.4rem;
      margin: 0 0 3rem;
    }
  }

  .about__content {
    text-align: left;

    p {
      font-size: 2rem;
      line-height: 2.7rem;
      margin: 0 0 3rem;
    }
  }
}

.section--contact {
  background-color: $mondo;
  text-align: center;

  .section__title {
    color: $color-background;
  }

  p {
    color: $parchment;
    font-size: 3rem;
    line-height: 3.7rem;
    margin: 0 0 3rem;
  }

  .link {
    color: $buttercup;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .button {
    border: 0.5rem solid $buttercup;
    color: $buttercup;
    display: inline-block;
    font-weight: bold;
    font-size: 3rem;
    line-height: 5.8rem;
    padding: 0 3rem;
    text-decoration: none;

    &:hover {
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-name: pulse;
    }
  }
}

@media (min-width: $screen-sm) {
  .section__title {
    font-size: 8rem;
    line-height: 8rem;
    padding: 0 3rem;

    &:after {
      bottom: 0.75rem;
      height: 1.5rem;
    }
  }

  .section--join {
    p {
      font-size: 3rem;
      margin-top: 3rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .section--services {
    .dot__title {
      font-size: 2.2rem;
      line-height: 2.56rem;
    }
  }

  .section--photos {
    padding: 0;

    .section__photo {
      min-height: 25vw;
    }
  }
}

@media (min-width: $screen-md) {
  .section--services {
    .dot__title {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }
  }
}

@media (min-width: $screen-lg) {
  .section--services {
    .dot__title {
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }
}
