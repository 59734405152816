// ==========================================================================
// Offer
// ==========================================================================

.offer {
  animation-fill-mode: both;
  font-weight: bold;
  padding: 8.8rem 1.5rem 6rem;
  position: relative;

  &:first-child {
    padding-bottom: 5rem;
  }

  &:hover {
    .offer__icon {
      animation: jello 1s 1 ease;
    }
  }
}

.offer__container {
  border: 0.8rem solid $mondo;
  margin: 0 auto;
  max-width: 35rem;
}

.offer__top {
  background: $parchment;
  border: 0.8rem solid $mondo;
  border-radius: 6.7rem;
  height: 13.4rem;
  left: 50%;
  position: absolute;
  top: 4rem;
  transform: translate(-50%, 0);
  width: 13.4rem;

  &:after {
    background: $mondo;
    border-radius: 0.6rem;
    content: '';
    height: 1.2rem;
    left: 50%;
    position: absolute;
    top: 1.7rem;
    transform: translate(-50%, 0);
    width: 1.2rem;
  }
}

.offer__content {
  background: $parchment;
  padding: 12rem 0 2.75rem;
  position: relative;
}

.offer__icon {
  height: 8.7rem;
  left: 50%;
  position: absolute;
  top: 1rem;
  margin-left: -5.1rem;
  width: 10.2rem;

  &.offer__icon--dweller {
    height: 9.9rem;
    margin-left: -3.45rem;
    width: 6.9rem;
  }
}

.offer__title {
  font-size: 3rem;
  line-height: 1;
  margin: 0 0 4rem;
  text-align: center;
}

.offer__wrapper {
  position: relative;
}

.offer__input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.offer__label {
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: normal;
  padding: .8rem;
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 50%;

  &::after {
    background: $light-wisteria;
    border-radius: 0.6rem;
    content: "";
    display: block;
    height: 1.2rem;
    margin-left: -.6rem;
    position: absolute;
    bottom: -.6rem;
    left: 50%;
    width: 1.2rem;
    opacity: 0;
    transform: translateY(50%);
    transition: transform .4s, opacity .4s;
  }

  .offer__input:checked + & {
    font-weight: bold;

    &::after {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  & ~ & {
    left: 50%;
  }
}

.offer__prices {
  background: $light-wisteria;
  padding: 2.5rem 0 2.75rem;

  .offer__input:not(:checked) + .offer__label + & {
    display: none;
  }

  &.offer__prices--dweller {
    background: $mustard;
  }
}

.offer__price {
  color: $mondo;
  font-size: 4rem;
  line-height: 5rem;
}

.offer__price-vat {
  color: $white;
  font-size: 3rem;
  line-height: 3.5rem;
}

.offer__available {
  color: $mondo;
  font-size: 3rem;
  line-height: 3.5rem;
  margin-top: 1.75rem;
  position: relative;

  del,
  ins {
    text-decoration: none;
  }

  ins {
    background: $mustard;
    border-radius: 3px;
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1.5;
    padding: 0 .5rem;
    position: absolute;
    text-transform: uppercase;
    transform: translate(-45%, 115%) rotate(-12deg);
  }
}

.offer__notes {
  font-weight: normal;
  margin: 4rem auto 0;
  max-width: 35rem;
}

@media (min-width: $screen-sm) {
  .offer {
    &:first-child {
      padding-bottom: 10rem;
    }
  }
}
