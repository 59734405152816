// ==========================================================================
// Buttons
// ==========================================================================

button,
.button {
  text-align: center;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;

  &:hover {

  }
}

.button--disabled,
.button[disabled] {

}