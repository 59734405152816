// ==========================================================================
// Footer
// ==========================================================================

.footer {
  background: $darker-mondo;
  border-bottom: 5rem solid $darker-mondo;
  padding: 5rem 0 0;

  .happyh0urs-link {
    display: block;
    height: 13.6rem;
    margin: 0 auto;
    width: 11.4rem;
  }

  .happyh0urs-logo {
    display: block;
    height: 13.6rem;
    width: 11.4rem;
  }

  p {
    color: $parchment;
    font-size: 1.6rem;
    line-height: 1.3;
    margin: 3rem 0 0;
    text-align: center;
  }

  a {
    color: $buttercup;
    text-decoration: none;

    &:hover, &:active {
      color: $buttercup;
    }
  }
}

@media (min-width: $screen-sm) {
  .footer {
    p {
      margin-top: 4rem;
    }
  }
}

@media (min-width: $screen-lg) {
  .footer {
    p {
      margin-top: 5rem;
    }
  }
}